<script setup>
import ThanksFeedback from "@cmp/ThanksFeedback.vue";
import { ref } from "vue";
import { useEvaluationsStore } from "@/stores/evaluations";

const icons = [
    { icon: "face-angry", color: "#C20000" },
    { icon: "face-sad-tear", color: "#E91414" },
    { icon: "face-smile", color: "#19CE00" },
    { icon: "face-laugh", color: "#129400" },
];

const iconsReverse = icons.reverse().map((icon) => {
    icon.vote = icons.length - icons.indexOf(icon);
    return icon;
});

function sendVote(icon) {
    const vote = icon.vote;
    useEvaluationsStore().postEvaluation(vote);
    thanks.value = icon.color;
    const audio = document.getElementById("audio");
    audio.play();
    setTimeout(() => {
        thanks.value = false;
    }, 2000);
}

const thanks = ref(false);
</script>

<template>
    <audio id="audio" src="/ping.mp3" class="opacity-0 absolute" />
    <transition>
        <ThanksFeedback class="z-20" v-if="thanks" :bg-color="thanks" />
    </transition>
    <div class="text-center absolute inset-0 flex flex-col justify-evenly">
        <h1 class="text-7xl mt-3">Dai un voto alla tua esperienza!</h1>

        <div id="emojis">
            <div class="flex justify-center">
                <div class="relative" v-for="el in iconsReverse" :key="el.vote">
                    <transition>
                        <div
                            class="absolute transition-all duration-500 ease-linear top-1/2 left-1/2 w-0 aspect-square -translate-x-1/2 -translate-y-1/2 rounded-full z-10"
                            :class="{
                                'w-[1999px]': thanks && thanks === el.color,
                            }"
                            :style="{
                                'background-color': el.color,
                            }"
                        />
                    </transition>
                    <font-awesome-icon
                        :icon="el.icon"
                        class="text-9xl m-5 z-0"
                        :color="el.color"
                        @click="sendVote(el)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
