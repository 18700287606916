import { defineStore } from "pinia";
import evaluations from "@/services/evaluations";

export const useEvaluationsStore = defineStore("evaluations", {
    state: () => ({}),
    actions: {
        async postEvaluation(vote) {
            // get evaluations from local storage
            const evaluationsLS = JSON.parse(
                localStorage.getItem("evaluations") || "[]"
            );
            // add new evaluation [vote, timestamp in seconds]
            evaluationsLS.push([vote, Math.floor(Date.now() / 1000)]);
            // save evaluations to local storage
            localStorage.setItem("evaluations", JSON.stringify(evaluationsLS));
            // send evaluation to server
            await evaluations.post(vote);
        },
    },
    getters: {},
});
